import { Paper, Typography, Box, Stack, InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";

import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

import { Link as RouterLink, Navigate, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from "react";

import { countries } from "../countries";
import axios from 'axios';
import ScrollToTopOnMount from "./scrolltoview";
import { BACKEND, BACKEND_ENDPOINTS } from "..";
import { NewUserContext } from "../pages/auth";
import { getQueryParams } from "../utils/getQueryParams";

// Import the functions you need from the SDKs you need


export default function Signup(props) {
    const [country, setCountry] = useState({
        label: "Canada",
        value: "+1"
    });
    const [number, setNumber] = useState('');
    const [email, setEmail] = useState('');

    const { userDetails, setUserDetails } = useContext(NewUserContext);    
    const navigate = useNavigate();

    const params = getQueryParams(window.location.href)

    useEffect(() => {
        document.getElementById('recaptchaContainer').style.visibility = 'hidden'
    })

    function checkLogin() {
        axios.post(`${BACKEND}${BACKEND_ENDPOINTS['checkuser']}`, {
            country_code: country.value,
            number: number,
        }, { headers: { 
            "x-api-key": process.env.REACT_APP_CHECK_USER_KEY}
        })
        .then((res) => {
            if (!res.data.isUsed) {
                setUserDetails({
                    'country': country.value,
                    'countryLabel': country.label,
                    'number': number,
                    'email': email,
                    'resellerToken': params?._at
                })
        
                navigate("/Auth", {state: {page: 'otp'}})
            }
            else {
                props.updateSnackbar("account already registered, please sign in_", 'red')
                navigate("/Signin")
            }
            props.toggleSpinner(false)
        })
        .catch((err) => {
            props.toggleSpinner(false)
            props.updateSnackbar(err.message, 'red')
        })
    }

    const handleSubmit = (e) => {      
        e.preventDefault()
        
        props.toggleSpinner(true)
        checkLogin()
    }

    return (
        <Paper component="div"
               sx={{ display: 'flex', flexDirection: 'column',
               py: 10, px: 4, mt: 3, width: {xs: '70vw', sm: '400px'},
               borderRadius:4 }}>
            <ScrollToTopOnMount /> 
            <Stack direction='row' spacing={1}>
                <Box bgcolor="#BFBFBF" width='52px' height='2px'></Box>
                <Box bgcolor="#EEEEEE" width='52px' height='2px'></Box>
                <Box bgcolor="#EEEEEE" width='52px' height='2px'></Box>
                <Box bgcolor="#EEEEEE" width='52px' height='2px'></Box>
            </Stack>

            <Typography variant="h5" fontWeight={700} mt={3.5}>Create your account</Typography>

            
            <Stack direction='column' pt={6}>
                <form onSubmit={(e) => handleSubmit(e)}>
                <TextField
                    id="outlined-select-country"
                    select
                    label="Country"
                    defaultValue={countries}
                    value={country}
                    helperText="Please select your country"
                    onChange={(e) => {
                        setCountry(e.target.value)
                    }}
                    >
                         {countries.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option.label}
                            </MenuItem>
                        ))}
                </TextField>
                    <Stack display='flex' direction='row' mt={3}>
                        {/* <TextField id="outlined-basic" 
                                   variant="outlined" 
                                   value={country.value}
                                   sx={{ width: '70px'}}
                                   disabled/> */}
                        <TextField label="Phone number" 
                                    type="phone"
                                    required
                                   variant="outlined" 
                                   sx={{ flexGrow: 1 }}
                                   InputProps={{
                                    startAdornment: <InputAdornment position="start">{country.value}</InputAdornment>,
                                    }}
                                   onKeyUp={(e) => {setNumber(country.value + e.target.value)}}/>
                    </Stack>

                    <Stack display='flex' direction='row' mt={3}>
                        <TextField label="Email" 
                                    type="email"
                                    required
                                   variant="outlined" 
                                   sx={{ ml: 0, flexGrow: 1 }}
                                   onKeyUp={(e) => {setEmail(e.target.value)}}/>
                    </Stack>
                    
                    <Typography variant="subtitle1" mt={3} color="grey" fontSize={11}>
                    By submitting these information, I agree to receive OTP verification code from NativeTalk at the phone number provided above. 
                    I understand I will receive messages, data rates may apply, reply stop to opt out.
                    </Typography>
                    
                    <Button type="submit" color='success' variant="contained"
                        sx={{  mt: 7.5, py: 1.5, backgroundColor: '#3EBF0F', textTransform: 'none', width: '100%' }}>
                            Proceed
                    </Button>
                    </form>
                    <Typography variant="body2" mt={4}>
                        Already have an account?
                        <RouterLink to='/Signin'> <Link color="#2E368F" variant="span">Sign in</Link></RouterLink>
                    </Typography>
                    {/* <Link color="#2E368F" mt={3} textAlign="right" >Sign in</Link>
                    <Button  color='success' href="#plans" variant="contained"
                    
                    sx={{  my: 3, py: 1.5, backgroundColor: '#3EBF0F', textTransform: 'none' }}>
                        Log in
                    </Button>
            
                     */}
                    
                </Stack>
        </Paper>
    )
}