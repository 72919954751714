import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { CardMedia, Typography, Box, Button } from '@mui/material';
import { IOSSwitch } from '../utils/iosSwitch';
import { BACKEND_ENDPOINTS, BACKEND } from '..';
import axios from 'axios';
import us from '../images/US.png';
import ng from '../images/NG.png';

import { Link as RouterLink } from 'react-router-dom';
import formatAmount from '../utils/formatAmount';

const flagImages = {
  USD: us,
  NGN: ng
};

export default function Plans({updateSnackbar, toggleSpinner}) {
  const [plansArray, setPlansArray] = useState([])
  const [allPlans, setAllPlans] = useState([])

  useEffect(() => {  
    if (sessionStorage.getItem('session')) {
      axios.post(`${BACKEND}${BACKEND_ENDPOINTS['getavailableplans']}`, {
          login: sessionStorage.getItem('login'),
          session: sessionStorage.getItem('session'),
      }, {headers: {'x-api-key': process.env.REACT_APP_GET_AVAILABLE_PLANS_KEY}})
      .then((res) => {
          if (res.data.status == 'successful') {    
              setPlansArray(res.data.plans)
          } else {
              updateSnackbar(res.data.message, 'black')
          }
      })
      .catch((err) => {
          updateSnackbar(err?.response?.data?.message, 'red')
          console.log(err)
      })
      .finally(() => {
        toggleSpinner(false)
      })
    } else {
      axios.get(`${BACKEND}${BACKEND_ENDPOINTS['getallplans']}`,
      {headers: {'x-api-key': process.env.REACT_APP_GET_ALL_PLANS_KEY}})
      .then((res) => {
          if (res.data.status == 'successful') {    
              setAllPlans(res.data.plans)
              setPlansArray(res.data.plans.filter(plan => plan.currency === "USD"))
          } else {
              updateSnackbar(res.data.message, 'black')
          }
      })
      .catch((err) => {
          updateSnackbar(err?.response?.data?.message, 'red')
          console.log(err)
      })
      .finally(() => {
        toggleSpinner(false)
      })
    }
   
  }, []);  

  const handleChange = (event) => {
    if (event.target.checked) {
      setPlansArray(allPlans.filter(plan => plan.currency === "NGN"))
    } else {
      setPlansArray(allPlans.filter(plan => plan.currency === "USD"))
    }
  }

  return (
    <>
      {!sessionStorage.getItem('session') &&
      <Box display='inline-flex' alignItems='center' py={3} gap={2}>
        <span>DOLLAR PLANS</span>
          <IOSSwitch 
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
             />
        <span>NAIRA PLANS</span>
      </Box>
      }
      

      <Grid container spacing={5} sx={{ px: { xs: 1, sm: 5}}} >
        {plansArray.map((plan) => {
          return (
          <Grid
                item
                key={plan.planId}
                xs={12}
                sm={6}
                md={4}
                
              >
                <Card  sx={{ textAlign: 'center', boxShadow: 5, pb: 2, pt: 2, borderRadius: 3, height: '400px', position: 'relative' }} >
                  <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <CardMedia
                      component='img'
                      alt={plan.currency}
                      src={flagImages[plan.currency]}
                      sx={{  width: '100px' }}
                      />
                  </Box>
                  <Typography variant='h5' fontWeight={700} pt={1}>{plan.currency}</Typography>
                  <CardContent sx={{ pt: 1 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'baseline',
                        
                      }}
                    > 
                      <Box>
                          <Box>
                            <Typography component="h2" fontWeight={700} variant="h3" color="#929292">
                              {formatAmount(plan.currency, plan.price)}
                            </Typography>
                            <Typography variant="subtitle1" fontWeight={700} color="#929292">
                              {plan.description}
                            </Typography>

                            <Typography variant="subtitle2" color="#929292">
                              {plan.note}
                            </Typography>                            
                          </Box>
                      </Box>                  
                    </Box>

                  </CardContent>

                  <Box sx={{ position: 'absolute', width: '100%', bottom: '10px'}} >
                    <CardActions sx={{ 'display': 'flex', justifyContent: 'center'}}>
                      <RouterLink to="/Dashboard" 
                      state={{ page: 'confirmBuyPlan', 
                      plan: plan.vs_plan_id, price: plan.price, 
                      formattedPrice: formatAmount(plan.currency, plan.price), 
                      description: plan.description,
                      currency: plan.currency,
                      planCode: plan.plan_code
                    }} 
                      style={{ textDecoration: 'none', width: '100%' }}>
                        <Button color='success' 
                                variant="contained" 
                                sx={{ mt: 2,  mx: 1.5, backgroundColor: '#3EBF0F', textTransform: 'none',
                                      width: '50%'  }}>
                          Buy Now
                        </Button>
                      </RouterLink>
                    </CardActions>
                    <Typography variant='subtitle2' color='#929292'>
                      All plans valid for 30days
                    </Typography>
                  </Box>
                </Card>
              </Grid>
        )})}
      </Grid>
    </>          
  )
}
  