import React from 'react';
import { Navigate } from 'react-router-dom';

const isAuthenticated = () => {
  const token = sessionStorage.getItem('session') || localStorage.getItem('session');
  
  return !!token;
};

const PrivateRoute = ({ children }) => {
  return isAuthenticated() ? (
    children
  ) : (
    <Navigate to="/Signin" replace state={{ from: '/AddFunds' }} />
  );
};

export default PrivateRoute;
