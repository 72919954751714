export function getQueryParams(url) {
    // Create a URL object from the provided URL
    const urlObj = new URL(url);

    // Get the search part of the URL
    const queryString = urlObj.search;

    // Create a URLSearchParams object
    const urlParams = new URLSearchParams(queryString);

    // Initialize an empty object to store query parameters
    const queryParams = {};

    // Loop through all query parameters and add them to the object
    for (const [key, value] of urlParams.entries()) {
        queryParams[key] = value;
    }

    return queryParams;
}