import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import { Box, Button, Stack, Paper, FormControl, TextField, Typography, CircularProgress } from '@mui/material';
import cardNumberIcon from '../../../images/cardNumberIcon.svg';
import stripePaymentIcon from '../../../images/stripe-payment-icon.png';

import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useState, useEffect } from "react";
import axios from "axios";
import { BACKEND, ENDPOINTS, BASE_URL_VOIPSWITCH } from "../../../index";

import { Navigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace';


// CheckoutForm with subscription implemented
export function CheckoutForm2(props) {
  const [paymentSuccess, setPaymentSuccess] = useState(false)
  const [paymentFailed, setPaymentFailed] = useState(false)

  const [disableSubmit, setDisableSubmit] = useState(false)

  const stripe = useStripe();
  const elements = useElements();
  const storedUser = sessionStorage.getItem('userDetails');
  const userDetails = JSON.parse(storedUser)

  const handleSubmit = async (event) => {
    event.preventDefault()  
    
    setDisableSubmit(true)

    document.getElementById("circularProgress").style.visibility = "visible"

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }    
    const result1 = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
    })

    if (result1.error) {
      document.getElementById("circularProgress").style.visibility = "hidden"
      
      setDisableSubmit(false)
      setPaymentFailed(true)
    } else {
      axios.post(`${BACKEND}${ENDPOINTS['createStripeSubscription']}`, {
        client: "AccordCalling",
        product: props.product,
        idClient: userDetails['idClient'],
        paymentMethod: result1.paymentMethod.id
      })
      .then((res) => {

        stripe.confirmCardPayment(res["data"]["clientSecret"], {
            payment_method: {
            card: elements.getElement(CardNumberElement),
            billing_details: {
              name: userDetails["firstName"],
            },
          }
        }).then((result) => {        
          if(result.error) {
            alert(result.error.message);
            document.getElementById("circularProgress").style.visibility = "hidden"
            setDisableSubmit(false)
            setPaymentFailed(true)  
          } else {
            alert("subscription successful")  
            document.getElementById("circularProgress").style.visibility = "hidden"
            setDisableSubmit(false)
            setPaymentSuccess(true)  
          }
        })
      })
      .catch((err) => {
        document.getElementById("circularProgress").style.visibility = "hidden"
        setDisableSubmit(false)
        setPaymentFailed(true)  
      })
    }
  }


  if (paymentSuccess)
    return <Navigate to="/Dashboard" state={{ page: "addFundsSuccessful"}} />
  if (paymentFailed)
    return <Navigate to="/Dashboard" state={{ page: "addFundsFailed"}} />
  else
  return (
      <Box display='flex' justifyContent='center'>
      <Paper sx={{pt: 3, pb: 10, mt: 3, px: {xs: 2, md: 5}, width: {xs: '80vw', md: '50vw', lg: '600px'}}} position="relative">
        <CircularProgress id="circularProgress" sx={{ visibility:"hidden", position: "absolute", left: "45%", top: "45%" }} color="success" />
        <Box display='flex' px={3} alignItems='center'>
            <RouterLink to='/Dashboard' state={{page: 'overview' }} style={{ textDecoration: 'none', marginTop: '-24px' }}>
                <KeyboardBackspace sx={{ position: 'absolute', color: 'black' }}/>
            </RouterLink>
            {/* <Typography variant="h5" fontWeight={700} sx={{ mx: 'auto'}}>Add Funds</Typography> */}
        </Box>
        <Stack component='form' onSubmit={(e) => handleSubmit(e)} direction='column' pt={3}> 
                
                <Typography variant="p" mt={5} mb={2} >Card Number</Typography>
                <Box position='relative' border={1} borderRadius={1} borderColor='#CBCBCB' px={3} py={2}>
                  <CardNumberElement />
                  <Box component='img'
                       position='absolute'
                       right="15px"
                       top="15px"
                       src={cardNumberIcon} />
                  
                </Box>
                <Stack display='flex' direction='row' mt={4} spacing={3}>
                  <Box flexGrow={1}>
                    <Typography variant='p'>Expiration Date</Typography>                    
                  
                    <Box border={1} mt={2} borderRadius={1} borderColor='#CBCBCB' px={3} py={2}>
                      <CardExpiryElement />
                    </Box>
                  </Box>  
                  <Box flexGrow={1}>
                    <Typography  variant='p'>CVV</Typography>
                    <Box border={1} mt={2} borderRadius={1} borderColor='#CBCBCB' px={3} py={2}>
                      <CardCvcElement />
                    </Box>
                  </Box>
                </Stack>
                  <Button disabled={disableSubmit} type='submit' color='success' variant="contained"
                    sx={{  mt: 7.5, py: 1.5, backgroundColor: '#3EBF0F', textTransform: 'none', width: '100%' }}>
                        Make Payment
                    </Button>                  
            <Box component='img' 
                  mt={10}
                  sx={{width: {xs: '50vw', md: '40vw', lg: '250px'}}}                   src={stripePaymentIcon} 
                  mx='auto'
                 />
        </Stack>
        </Paper>
      </Box>
  )
}