import axios from 'axios';
import { BACKEND, BACKEND_ENDPOINTS } from '..';

export function getUserDetails (details) {
    return new Promise((resolve, reject) => {
        axios.post(`${BACKEND}${BACKEND_ENDPOINTS['getuserdetails']}`, {
            login: sessionStorage.getItem('login'),
            session: sessionStorage.getItem('session'),
            details: details
        }, { 
            headers: { "x-api-key": process.env.REACT_APP_GETUSERDETAILS_KEY }
        })
        .then((res) => {
            if (res.data.status !== "successful") {
                const error = new Error(res.data.message);
                error.errorCode = res.data.errCode;
                reject(error);
            } else {
                resolve(res.data.details);
            }
        })
        .catch((err) => {
            reject(err);
        });
    });
};
