import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import { Box, Button, Paper, Stack, FormControl, Radio, RadioGroup, FormControlLabel, Typography } from '@mui/material';
import cardNumberIcon from '../../../images/cardNumberIcon.svg';
import stripePaymentIcon from '../../../images/stripe-payment-icon.png';

import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useState, useEffect } from "react";
import axios from "axios";
import { BACKEND, ENDPOINTS, BACKEND_ENDPOINTS, BASE_URL_VOIPSWITCH, BASE_URL_VOIPSWITCH2, EMAILAPP } from "../../../index";

import { Navigate, useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace';
import AddPlanWithPstk from "./addPlanWithPstk";
import { getUserDetails } from "../../../utils/getUserDetails";
import { getTechPrefix } from "../../../utils/getTechPrefix";


// CheckoutForm without subscription implemented, with options of wallet or card
export function MakePlanPayment(props) {
  const navigate = useNavigate()

  const [disableSubmit, setDisableSubmit] = useState(false)
  const [clientSecret, setClientSecret] = useState('')

  const [idClient, setIdClient] = useState()
  const [email, setEmail] = useState()
  const [login, setLogin] = useState()
  const [resellerId, setResellerId] = useState()
  const [webPassword, setWebPassword] = useState()
  const [number, setNumber] = useState()
  const [paymentGateway, setPaymentGateway] = useState()

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    props.toggleSpinner(true)
    axios.post(`${BACKEND}${BACKEND_ENDPOINTS['getStripeSecret']}`, {
      login: sessionStorage.getItem('login'),
      amount: Number(props.price) * 100,
      session: sessionStorage.getItem('session')
    }, { headers: { 
      "x-api-key": process.env.REACT_APP_STRIPE_CREATE_PAYMENT_KEY}
    })
    .then((res) => {
      setClientSecret(res['data']['clientSecret'])
      props.toggleSpinner(false)
    })

    const detailsToFetch = ["idClient", "payment_gateway", "email", "login", "webPassword", "reseller_id"];
        getUserDetails(detailsToFetch)
      .then((details) => {
          setIdClient(details.idClient)
          setPaymentGateway(details.payment_gateway)
          setEmail(details.email)
          setLogin(details.login)
          setWebPassword(details.webPassword)
          setResellerId(details.reseller_id)
      })
      
    }, [props.price, paymentGateway])

    useEffect(() => {
      if (number !== undefined) {
          props.toggleSpinner(false);
          onPlanAdded();
      }
    }, [number]);
  
        
  const handleSubmit = async (event) => {
    if (!stripe || !elements) {
      return;
    }

    event.preventDefault();
    props.toggleSpinner(true)

    setDisableSubmit(true)    

    const result1 = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
    })
    if (result1.error) {            
      setDisableSubmit(false)
      props.toggleSpinner(false)
      props.updateSnackbar(result1.error.message, 'red')
    } else {

      const result2 = await stripe.confirmCardPayment(clientSecret, {
          payment_method: result1.paymentMethod.id,
        })  

      if (result2.error) {
        setDisableSubmit(false)
        props.toggleSpinner(false)
        props.updateSnackbar(result1.error.message, 'red')
      } else {
        setDisableSubmit(false)
        
        axios.post(`${BASE_URL_VOIPSWITCH}${ENDPOINTS['addFunds']}`, {
          "money":props.price,
          "paymentType":"PrePaid", 
          "idClient":idClient,
          "clientType":32,
          "addToInvoice":false,
          "description":"Web top up"
        })
        .then(() => {
          addPlan()
        })
        .catch((err) => {
          props.toggleSpinner(false)
          props.updateSnackbar(err.message, 'red')
        })
      } 
    }
  };

  const addPlan = (e) => {
    if (e) e.preventDefault()
    props.toggleSpinner(true)
  
    axios.post(`${BASE_URL_VOIPSWITCH}${ENDPOINTS['addPlan']}`, {
        clientId: idClient,
        clientType: 32,
        planId: props.plan,
        endDate: {"override":false,"date":"0001-01-01T00:00:00.0000000"}
      })
      .then((res) => {        
        if (res['data']['status'] === -1) {
            let message = res['data']['responseStatus']['message']
            throw new Error(message);
        } else {
            axios.post(`${EMAILAPP}${ENDPOINTS['accordMail']}`, {
              "to_email": email,
              "email_template": "template1",
              "to_name": idClient,
              "country_name": props.currency,
              "plan_title": "Nativetalk " + props.currency + " plan " + props.description,
              "amount": props.formattedPrice,
              "minutes": props.description
            }, {headers: {'Authorization': process.env.REACT_APP_EMAILAPP_KEY}}).catch(err => {})
            props.updateSnackbar("Plan added successfully", 'green')

            axios.post(`${BACKEND}${BACKEND_ENDPOINTS['getnumber']}`, {
              login: sessionStorage.getItem('login'),
              session: sessionStorage.getItem('session'),
            }, {
                headers: { "x-api-key": process.env.REACT_APP_GET_NUMBER_KEY }
            })
            .then((res) => {
                if (res.data.number) {
                    setNumber(res.data.number)
                } else {
                    addNumber()
                }
            })
            .catch((err) => {
              props.updateSnackbar(err.message, 'red')
              props.toggleSpinner(false)
            })
        }
      })
      .catch((err) => {
        props.toggleSpinner(false)
        props.updateSnackbar(err.message, 'red')
        if (err.message == "Initial cost is greater than client balance. Add some funds to proceed.")
              return navigate('/Addfunds')
    })
  }

  const addNumber = () => {
      try {
        // outgoing number
        axios.post(`${BASE_URL_VOIPSWITCH}${ENDPOINTS['getNumberList']}`, {
            "areaId":0,
            "countryId":1,
            "number":"*",
            "pageOffset":0,
            "pageSize":0
          })
          .then((res) => {
            let  i = -1;
            let selectedNumber = ''
            // loop through until an available number is found
            do {
                i++    
                selectedNumber = res['data']['data'][i]['number']
            } while(res['data']['data'][i]['status'] !== "Available" 
                    && i < (res['data']['data']).length - 1);
            
            if (res['data']['data'].length - 1 === i && res['data']['data'][i]['status'] !== "Available" ) {
                props.updateSnackbar('no numbers available')
                onPlanAdded()
            } else {                
                axios.post(`${BACKEND}${BACKEND_ENDPOINTS['addnumber']}`, {
                  login: sessionStorage.getItem('login'),
                  number: selectedNumber,
                  session: sessionStorage.getItem('session'),
                }, { headers: { 
                    "x-api-key": process.env.REACT_APP_ADD_NUMBER_KEY}
                })
                .then((res) => {
                    if (res.data.status != "successful") {
                        throw new Error(res.data.message);
                    }
                    
                    props.updateSnackbar('number attached successfully.', 'green')
                }) 
                .catch((err) => {
                    props.updateSnackbar(err.message, 'red');
                    // onPlanAdded()
                })
            }
          })    
          .catch((err) => {
            props.updateSnackbar('could not map number for incoming', 'grey')
          })
        }
        catch {
        }
    props.toggleSpinner(false)
  }
  
  const onPlanAdded = () => {
    return navigate("/Dashboard", {state: { page: "buyPlanSuccessful", formattedPrice: props.formattedPrice, number: number}})
  }
  
  const [selectedValue, setSelectedValue] = useState('a');
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: 'size-radio-button-demo',
    inputProps: { 'aria-label': item },
  });

  return (
    <Box display='flex' justifyContent='center'>
      <Paper sx={{pt: 3, pb: 10, mt: 3, px: {xs: 2, md: 5}, width: {xs: '80vw', md: '50vw', lg: '600px'}}} position="relative">
        <Box display='flex' px={3} alignItems='center'>
            <KeyboardBackspace onClick={() => navigate('/Dashboard', {state: {'page': 'overview'}})} sx={{ position: 'absolute', color: 'black' }}/>
            <Typography variant="h5" fontWeight={700} sx={{ mx: 'auto'}}>Calling Plans</Typography>
        </Box>
        <RadioGroup sx={{mt:3}} row aria-labelledby="payment option" name="row-radio-buttons-group">
          <FormControlLabel value="debit" control={<Radio {...controlProps('a')} size='small' color="success" />} label="Fund with debit/credit card" />
          {
          !props.recurring &&
          <FormControlLabel value="wallet" control={<Radio {...controlProps('b')} size='small' color="success" />} label="Fund with NativeTalk Wallet" />
          }
          
        </RadioGroup>

        {(() => {
          if (selectedValue == 'a') {
            if (paymentGateway == "PAYSTACK")  
              return <AddPlanWithPstk updateSnackbar={props.updateSnackbar} 
              toggleSpinner={props.toggleSpinner} 
              formattedPrice={props.formattedPrice} 
              price={props.price} plan={props.plan} 
              planCode={props.planCode}
              recurring={props.recurring}
              /> 

            return (
                <Stack component='form' onSubmit={(e) => handleSubmit(e)} direction='column' pt={3}>
                    <Typography variant="p" mt={5} mb={2} >Card Number</Typography>
                    <Box position='relative' border={1} borderRadius={1} borderColor='#CBCBCB' px={3} py={2}>
                      <CardNumberElement />
                      <Box component='img'
                          position='absolute'
                          right="15px"
                          top="15px"
                          src={cardNumberIcon} />
                      
                    </Box>
                    <Stack display='flex' direction='row' mt={4} spacing={3}>
                      <Box flexGrow={1}>
                        <Typography variant='p'>Expiration Date</Typography>                    
                      
                        <Box border={1} mt={2} borderRadius={1} borderColor='#CBCBCB' px={3} py={2}>
                          <CardExpiryElement />
                        </Box>
                      </Box>  
                      <Box flexGrow={1}>
                        <Typography  variant='p'>CVV</Typography>
                        <Box border={1} mt={2} borderRadius={1} borderColor='#CBCBCB' px={3} py={2}>
                          <CardCvcElement />
                        </Box>
                      </Box>
                    </Stack>
                      <Button disabled={disableSubmit} type='submit' color='success' variant="contained"
                        sx={{  mt: 7.5, py: 1.5, backgroundColor: '#3EBF0F', textTransform: 'none', width: '100%' }}>
                            Make Payment
                        </Button>                  
                    <Box component='img' 
                          mt={10}
                          sx={{width: {xs: '50vw', md: '40vw', lg: '250px'}}} 
                          src={stripePaymentIcon} 
                          mx='auto'
                        />
                </Stack>
            )
          }
          else return (
            <Button onClick={addPlan} disabled={disableSubmit} type='submit' color='success' variant="contained"
              sx={{  mt: 7.5, py: 1.5, backgroundColor: '#3EBF0F', textTransform: 'none', width: '100%' }}>
                  Confirm
              </Button>                  
          )
        })()}
        
      </Paper>  
    </Box>
  )
}