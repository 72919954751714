import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Toolbar from "@mui/material/Toolbar";

import logo from "../images/ntLogoWhite.svg";
import back from "../images/backIcon.svg";
import android2 from "../images/android2.svg";
import iOS from "../images/iOS.svg";


import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";


export default function Header() {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1)
    };
    return (    
        <AppBar position="fixed" elevation={0}
            sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}`, 
            backgroundColor: "#3EBF0F", py: 1, px: { xs: 5, sm:  7 } }}
            >
            <Toolbar sx={{ flexWrap: 'wrap' }} >
                <RouterLink style={{textDecoration: 'none'}} to="/" >
                    <Box component="img"
                        src={logo}
                        alt='' 
                        sx={{ width: {xs: '70px', sm: '80px'}}}/>
                </RouterLink>
                <RouterLink style={{textDecoration: 'none'}} to="/Dashboard" state={{ page:"contactsupport"}}>
                        <Link
                        color="white"
                        href="#"
                        underline='none'
                        sx={{  mx: 1.5 }}
                        >
                            Contact Support
                        </Link>
                </RouterLink>
                
                <nav style={{marginLeft: 'auto', display: 'flex', alignItems: 'center'}} >
                    <a target='_blank' href="https://play.google.com/store/apps/details?id=app.nativetalk">
                    <Box component='img' 
                        sx={{mx: {sm: 5}}}
                        src={android2}>
                    </Box></a>
                    <a target='_blank' href="https://apps.apple.com/us/app/nativetalk-communications/id6473689247">
                    <Box component='img'
                        sx={{mx: {sm: 5}}} 
                        src={iOS}>
                    </Box></a>
                    
                    <Button>
                    <img src={back} onClick={handleGoBack} alt='' />
                    </Button>
                </nav>
                
            </Toolbar>
        </AppBar>
    )
}