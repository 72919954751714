import { useEffect } from "react";
import { Navigate } from "react-router-dom";

// Used to share app
export default function S() {
    useEffect(() => {
        const userAgent = navigator.userAgent;       

        
        if (/iPhone|iPad|iPod/i.test(userAgent) || /Macintosh|Mac OS X/i.test(userAgent)) {
            // Redirect iOS users to the App Store
            window.location.href = 'https://apps.apple.com/us/app/nativetalk-communications/id6473689247';
        } 

        else {
            // Redirect Android users to the Play Store
            window.location.href = 'https://play.google.com/store/search?q=nativetalk&c=apps';
        }
        // For other platforms, you may choose to handle differently or do nothing
    }, [])

    return <Navigate to="/" />
}