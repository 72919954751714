import axios from "axios";
import { Box, Paper, Stack, Typography, Input, InputAdornment, TextField } from "@mui/material"
import Button from "@mui/material/Button"
import FormControl from "@mui/material/FormControl"

import successIcon from '../../../images/successIcon.svg';
import failedIcon from '../../../images/failed.svg';

import { Link as RouterLink, Navigate } from "react-router-dom"

import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace';
import { BACKEND, BACKEND_ENDPOINTS, BASE_URL_VOIPSWITCH, ENDPOINTS } from "../../../index";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../header";
import { getUserDetails } from "../../../utils/getUserDetails";

export default function AddFunds({ updateSnackbar, toggleSpinner }) {
    const navigate = useNavigate()
    const [amount, setAmount] = useState('')

    // Paystack
    const [currency, setCurrency] = useState('')
    const [publicKey, setPublicKey] = useState('')
    const [email, setEmail] = useState('')
    const [idClient, setIdClient] = useState('')
    const [paymentGateway, setPaymentGateway] = useState('')

    useEffect(() => {
        toggleSpinner(true)
        if (!sessionStorage.getItem('session')) {
            sessionStorage.clear()
            return navigate('/Signin');
        }

        axios.post(`${BACKEND}${BACKEND_ENDPOINTS['addfunds']}`, {
            login: sessionStorage.getItem('login'),    
            session: sessionStorage.getItem('session'),
        }, { headers: { 
            "x-api-key": process.env.REACT_APP_ADDFUNDS_KEY}
        })
        .then((res) => {
            if (res.data.status != "successful") {
                throw new Error(res.data.message);
            }

            setCurrency(res.data.currency);
            setPublicKey(res.data.public_key)
            setPaymentGateway(res.data.payment_gateway)
        }) 
        .catch((err) => {
            updateSnackbar(err.message, 'red');
        })

        const detailsToFetch = ["login", "idClient"];
        getUserDetails(detailsToFetch)
        .then((details) => {
            setIdClient(details.idClient)
            setEmail(`${details.login}@gmail.com`)
            toggleSpinner(false)
        })
        .catch((error) => {
            console.error(error);
            toggleSpinner(false)
        });
    }, []);


    const makePayment = (e) => {
        e.preventDefault()
        if (paymentGateway == "STRIPE") {
            return navigate('/Dashboard', { state: { page: 'makePayment', amount: amount }})
        }

        const PaystackPop = window.customLib['PaystackPop']
        let total_price = amount * 100
        let handler = PaystackPop.setup({
            key: publicKey,                
            email: email,
            amount: total_price,
            currency: currency,
            onClose: function(){
                updateSnackbar("Payment cancelled", "black")
            },
            onerror: function(err) {
                return navigate('/Dashboard', { state: { page: 'addFundsFailed', error: err.message } })
            },
            callback: function(response) {
                axios.post(`${BASE_URL_VOIPSWITCH}${ENDPOINTS['addFunds']}`, {
                    "money": amount,
                    "paymentType":"PrePaid", 
                    "idClient":idClient,
                    "clientType":32,
                    "addToInvoice":false,
                    "description":"Web top up"
                    })
                    .then(() => {
                        return navigate('/Dashboard', { state: { page: 'addFundsSuccessful' } })
                    })
                    .catch((err) => {
                    document.getElementById("circularProgress").style.visibility = "hidden"
                })
            }
        })

        handler.openIframe();
    }

    return (
        <>
        <Header />
        <Paper component="div"
        sx={{ display: 'flex', flexDirection: 'column',
        pt: 3, pb: 10, mt: 15, mx: 'auto', width: {xs: '80vw', md: '50vw', lg: '500px'},
        borderRadius:4 }}>
     
     <Box display='flex' px={3} alignItems='center'>
        <KeyboardBackspace onClick={() => navigate(-1)} sx={{ position: 'absolute' }}/>
         <Typography variant="h5" fontWeight={700} sx={{ mx: 'auto'}}>Add Funds</Typography>
     </Box>
     <Box bgcolor="#E2E2E2" width='100%' height='2px'></Box>

     <Box px={3}>
         <Stack component='form' onSubmit={(e) => makePayment(e)} direction='column' pt={6}>
            <FormControl sx={{ pt: 3}}>
                <Input
                    id="input-with-icon-adornment"
                    type="number"
                    required
                    onChange={(e) => {
                        if (e.target.value >= 1) {
                            setAmount(e.target.value)
                        } 
                    }}
                    value={amount}
                    startAdornment={
                        <InputAdornment position="start">
                            {currency === 'NGN' ? '₦' : currency === 'USD' ? '$' : null}
                        </InputAdornment>
                    }
                    />   
                 {/* <TextField id="outlined-basic" type='number' label="Enter Specific Amount" variant="outlined" /> */}
             </FormControl>
             
             
             
            <Button type='submit' color='success' variant="contained"
            sx={{  mt: 7.5, py: 1.5, backgroundColor: '#3EBF0F', textTransform: 'none', width: '100%' }}>
                Make Payment
            </Button>
             
            </Stack>
        </Box>
    </Paper>
    </>
    )
}
  
export function AddFundsSuccessful() {
    const navigate = useNavigate()
    return (
            <Paper component="div"
            sx={{ display: 'flex', flexDirection: 'column',
            pt: 3, pb: 10, mt: 3, mx: 'auto', width: {xs: '90vw', sm: '600px'},
            borderRadius:4 }}>

        <Box display='flex' px={3} alignItems='center'>
            <KeyboardBackspace onClick={() => navigate(-1)} sx={{ position: 'absolute' }}/>
            <Typography variant="h5" fontWeight={700} sx={{ mx: 'auto'}}>Add Funds</Typography>
        </Box>
        <Box bgcolor="#E2E2E2" width='100%' height='2px'></Box>

        <Box px={3}>
            <Stack direction='column' pt={6} textAlign="center">
                <Box component='img'
                        width={120}
                        mx='auto'
                        src={successIcon} />
                <Typography variant="h5" fontWeight={700}>Success!</Typography>    
                <RouterLink to='/Dashboard' state={{page: 'overview' }}  style={{ textDecoration: 'none' }}>
                    <Button  color='success' variant="contained"
                    sx={{  mt: 7.5, py: 1.5, backgroundColor: '#3EBF0F', textTransform: 'none', width: '100%' }}>
                        Okay, Thank you
                    </Button>
                </RouterLink>
            </Stack>
        </Box>
    </Paper>
    )
}

export function AddFundsFailed(props) {
    return (
            <Paper component="div"
            sx={{ display: 'flex', flexDirection: 'column',
            pt: 3, pb: 10, mt: 3, mx: 'auto', width: {xs: '90vw', sm: '600px'},
            borderRadius:4 }}>

                <Box display='flex' px={3} alignItems='center'>
                    <RouterLink to='/AddFunds' style={{ textDecoration: 'none', marginTop: '-24px' }}>
                        <KeyboardBackspace sx={{ position: 'absolute', color: 'black' }}/>
                    </RouterLink>
                    <Typography variant="h5" fontWeight={700} sx={{ mx: 'auto'}}>Add Funds</Typography>
                </Box>
                <Box bgcolor="#E2E2E2" width='100%' height='2px'></Box>

                <Box px={3}>
                    <Stack direction='column' pt={6} textAlign="center">
                        <Box component='img'
                                width={120}
                                mx='auto'
                                src={failedIcon} />
                        <Typography variant="h5" mt={2} fontWeight={700}>Failed!</Typography> 
                        <Typography variant="body2">{props.error}</Typography>
                        <RouterLink to='/Dashboard' state={{page: 'overview' }}  style={{ textDecoration: 'none' }}>
                            <Button  color='success' variant="contained"
                            sx={{  mt: 7.5, py: 1.5, backgroundColor: '#3EBF0F', textTransform: 'none', width: '100%' }}>
                                Okay, Thank you
                            </Button>
                        </RouterLink>
                    </Stack>
                </Box>
            </Paper>
    )
}