export default function formatAmount(currency, price) {
   // Determine the appropriate locale based on the currency
   let locale = 'en-US'; // Default to 'en-US'
   if (currency === 'NGN') {
     locale = 'en-NG'; // Use 'en-NG' for Nigerian Naira
   }
   
   // Create a formatter using the Intl.NumberFormat API
   const formatter = new Intl.NumberFormat(locale, {
     style: 'currency',
     currency: currency,
     minimumFractionDigits: 0,
     maximumFractionDigits: 0,
   });
 
   // Use the formatter to format the price
   return formatter.format(price);
}
