import axios from "axios";
import { Box, Paper, Stack, Typography, Input, InputAdornment } from "@mui/material"
import Button from "@mui/material/Button"
import FormControl from "@mui/material/FormControl"
import { Link as RouterLink, Navigate } from "react-router-dom"
import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace';
import { ENDPOINTS, BASE_URL_VOIPSWITCH } from "../../../index";
import { useEffect, useState } from "react";
import Header from "../../header";
import { getUserDetails } from "../../../utils/getUserDetails";

function UserAddfuns(){
    const [amount, setAmount] = useState('')
    
    const [makePayment, setMakePayment] = useState(false)

    // Paystack
    const [paymentSuccess, setPaymentSuccess] = useState(false)
    const [paymentFailed, setPaymentFailed] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const [currency, setCurrency] = useState()
    const [login, setLogin] = useState()
    const [idClient, setIdClient] = useState()

    useEffect(() => {
        const detailsToFetch = ["currency", "login", "idClient"];
        getUserDetails(detailsToFetch)
        .then((details) => {
            setCurrency(details.currency)
            setLogin(details.login)
            setIdClient(details.idClient)
        })
    })

    const makeThePayment = (e) => {
        e.preventDefault()
        // if (currencyName == 'NGN') {
            // Paystack
            const PaystackPop = window.customLib['PaystackPop']
            let total_price = amount * 100
            let handler = PaystackPop.setup({
                key: 'pk_live_4c73f3f9f417baa3bfd6518592e5583a7c0b78fb',                
                email: `${login}@gmail.com`,
                amount: total_price,
                currency: currency,
                onClose: function(){
                    alert('Payment cancelled')
                },
                onerror: function(err) {
                    setPaymentFailed(true)
                },
                callback: function(response) {
                    // $('#spinner').removeClass('hidden');
                    axios.post(`${BASE_URL_VOIPSWITCH}${ENDPOINTS['addFunds']}`, {
                        "money": amount,
                        "paymentType":"PrePaid", 
                        "idClient":idClient,
                        "clientType":32,
                        "addToInvoice":false,
                        "description":"Web top up"
                      })
                      .then(() => {
                        setPaymentSuccess(true)
                      })
                      .catch((err) => {
                        document.getElementById("circularProgress").style.visibility = "hidden"
                    })
                }
            })
            handler.openIframe();
    }


    // Paystack
    if (paymentSuccess)
        return <Navigate to="/Dashboard" state={{ page: "addFundsSuccessful"}} />
    if (paymentFailed)
        return <Navigate to="/Dashboard" state={{ page: "addFundsFailed", error: errorMessage}} />


    if (makePayment) 
        return <Navigate to='/Dashboard' state={{page: 'makePayment', amount: amount }} />
    else
    return (
        <>
        <Header />
        <Paper component="div"
        sx={{ display: 'flex', flexDirection: 'column',
        pt: 3, pb: 10, mt: 15, mx: 'auto', width: {xs: '80vw', md: '50vw', lg: '500px'},
        borderRadius:4 }}>
     
     <Box display='flex' px={3} alignItems='center'>
         <RouterLink to='/Dashboard' state={{page: 'overview' }} style={{ textDecoration: 'none', marginTop: '-24px' }}>
             <KeyboardBackspace sx={{ position: 'absolute' }}/>
         </RouterLink>
         <Typography variant="h5" fontWeight={700} sx={{ mx: 'auto'}}>Add Funds</Typography>
     </Box>
     <Box bgcolor="#E2E2E2" width='100%' height='2px'></Box>

     <Box px={3}>
         <Stack component='form' onSubmit={(e) => makeThePayment(e)} direction='column' pt={6}>
            <FormControl sx={{ pt: 3}}>
                <Input
                    id="input-with-icon-adornment"
                    type="number"
                    required
                    onChange={(e) => {
                        if (e.target.value >= 1) {
                            setAmount(e.target.value)
                        } 
                    }}
                    value={amount}
                    startAdornment={
                        <InputAdornment position="start">
                            { currency === 'NGN' ? '₦' : '$'}
                        </InputAdornment>
                    }
                    />   
                 
             </FormControl>
             
             
             
            <Button type='submit' color='success' variant="contained"
            sx={{  mt: 7.5, py: 1.5, backgroundColor: '#3EBF0F', textTransform: 'none', width: '100%' }}>
                Make Payment
            </Button>
             
         </Stack>
     </Box>
 </Paper>
 </>
    )
}

export default UserAddfuns;