import { Paper, Typography, Box, Stack, getCircularProgressUtilityClass } from "@mui/material";

import Button from "@mui/material/Button";
import { useContext, useEffect } from "react";

import { Link as RouterLink, Navigate, useNavigate } from 'react-router-dom';

import { MuiOtpInput } from 'mui-one-time-password-input'
import { useState } from "react";

import { CircularProgress, Snackbar } from "@mui/material";
import axios from "axios";
import { BACKEND, BACKEND_ENDPOINTS } from "..";
import qs from 'qs';
import { NewUserContext } from "../pages/auth";

export default function Otp() {
    const navigate = useNavigate()
    const [otp, setOtp] = useState('')
    const [verified, setVerified] = useState(false)

    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');

    const { userDetails } = useContext(NewUserContext);

    const number = userDetails['number']
    const country = userDetails['country']

    // window.location.reload()    
    const handleChange = (newValue) => {
        setOtp(newValue)
      }

    const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }

    setOpen(false);
    };
    

    useEffect(() => {
        sendOtp()

    }, [])

    function sendOtp() {
        axios.post(`${BACKEND}${BACKEND_ENDPOINTS['sendotp']}`, {
            country_code: country,
            number: number
        }, { headers: { 
            "x-api-key": process.env.REACT_APP_OTP_KEY}
        })
        .then((res) => {
            setSnackMessage(res.data.message)
            setOpen(true);
        })
        .catch((err) => {
            setSnackMessage(err.message)
            setOpen(true);
            console.log(err.message)
        })
    }

    function verifyOtp() {
        axios.post(`${BACKEND}${BACKEND_ENDPOINTS['verifyotp']}`, {
            country_code: country,
            number: number,
            otp: otp
        }, { headers: { 
            "x-api-key": process.env.REACT_APP_VERIFY_OTP_KEY}
        })
        .then((res) => {
            if (res.data.isValid) {
                navigate("/Auth", {state: {page: 'createPassword' }})
            }
            else {
                throw new Error(res.data.message)
            }
        })
        .catch((err) => {
            setSnackMessage(err.message)
            setOpen(true);
        })
    }
    
    const signup = (e) => {
        e.preventDefault();
        
        document.getElementById("reverify").style.visibility = "hidden"
        
        if (otp != "") {
            verifyOtp(otp)
        }
        
    }

    
    return (
        <Paper component="div"
               sx={{ display: 'flex', flexDirection: 'column',
               py: 10, px: 4, mt: 3, width: {xs: '70vw', sm: '400px'},
               borderRadius:4 }}>
            <Stack direction='row' spacing={1}>
                <Box bgcolor="#BFBFBF" width='52px' height='2px'></Box>
                <Box bgcolor="#BFBFBF" width='52px' height='2px'></Box>
                <Box bgcolor="#EEEEEE" width='52px' height='2px'></Box>
                <Box bgcolor="#EEEEEE" width='52px' height='2px'></Box>
            </Stack>

            <Typography variant="h5" fontWeight={700} mt={3.5}>Enter Verification Code</Typography>
            
            {(() => {
                if (country !== "+234" && country !== "+1" && country !== "+44") return (
                    <Typography visibility='' variant="body2" mt={3} color="#2E368F">
                        Verify recaptcha, then input the otp verification code sent to {number}
                    </Typography>
                ) 
                else return (
                    <Typography visibility='' variant="body2" mt={3} color="#2E368F">
                        A verification code has been sent to {number}
                    </Typography>
                )
            })()}
            
            <Stack component='form' onSubmit={(e) => signup(e)} direction='column' pt={6}>
                {/* <Stack justifyContent='space-between' direction='row' sx={{ fontSize: '1.3rem'}}>
                    <Paper variant="outlined" sx={{ display: 'flex', alignItems: 'center', 
                                               justifyContent: 'center', width:'60px', height:'60px'}}>0</Paper>
                    <Paper variant="outlined" sx={{ display: 'flex', alignItems: 'center', 
                                               justifyContent: 'center', width:'60px', height:'60px'}}>5</Paper>
                    <Paper variant="outlined" sx={{ display: 'flex', alignItems: 'center', 
                                               justifyContent: 'center', width:'60px', height:'60px'}}>8</Paper>
                    <Paper variant="outlined" sx={{ display: 'flex', alignItems: 'center', 
                                            justifyContent: 'center', width:'60px', height:'60px'}}>6</Paper>
                </Stack> */} 

                <MuiOtpInput display='flex' gap={1} length={6} value={otp} onChange={handleChange} />
                <Button type='submit' id="sign-up-button" color='success' variant="contained"
                sx={{  mt: 7.5, py: 1.5, backgroundColor: '#3EBF0F', textTransform: 'none', width: '100%' }}>
                    Verify
                </Button>
                <Typography id="reverify" visibility='hidden' pt={1} color='red' variant='body2'>reverify recaptcha</Typography>
                 
                <CircularProgress id="circularProgress" sx={{ visibility:"hidden", position: "relative", left: {xs: "120px", md: "150px"}, top: '20px' }} color="success" />
                
                <Stack mt={4} color="#2E368F" direction="row" justifyContent="space-between">
                    {/* <Typography variant="body2" >
                        Send code again
                    </Typography> */}
                    <RouterLink to="/Auth" state={{page: 'signup'}}>
                        <Typography variant="body2" >
                            Change phone number
                        </Typography>
                    </RouterLink>
                </Stack>
                
            </Stack>

            <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
            message={snackMessage}
            // action={action}
            />
        </Paper>
    )
}