import { Box, Paper, Typography, Tabs, Tab, Stack, Snackbar, Modal } from "@mui/material";
import { Link } from "react-router-dom";

import { Link as RouterLink } from "react-router-dom";

import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace';

import PropTypes from 'prop-types';
import { useEffect, useState } from "react";

import ic_recur from "../../images/ic_recur.svg";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { ENDPOINTS, BASE_URL_VOIPSWITCH, BACKEND, BACKEND_ENDPOINTS } from "../../index";
import axios from "axios";
import optionIcon from '../../images/options.svg';
import ScrollToTopOnMount from "../scrolltoview";
import { getUserDetails } from "../../utils/getUserDetails";
import formatDateTime from "../../utils/formatDateTime";

const columns2 = [
{ id: 'date', label: 'STARTED', minWidth: 100 },
{ id: 'nextPayment', label: 'NEXT PAYMENT', minWidth: 100 },
{ id: 'plan', label: 'PLAN', minWidth: 100, align: 'left',},
{ id: 'amount', label: 'AMOUT', minWidth: 100, align: 'left',},
{ id: 'duration', label: 'DURATION', minWidth: 100, align: 'left',},
{ id: 'subCode', label: '', minWidth: 50, align: 'left',},
{ id: 'status', label: '', minWidth: 50, align: 'left',},
];

function createData(date, nextPayment, plan, amount, duration, subCode, status) {
return { date, nextPayment, plan, amount, duration, subCode, status};
}

    
export default function RecurringPlans(props) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(7);
    const [rows, setRows] = useState([]);
    const [refreshPage, setRefreshPage] = useState(false)

    const cancelSubscription = (subCode) => {
        props.toggleSpinner(true)    
        axios.post(`${BACKEND}${BACKEND_ENDPOINTS['cancelsubscription']}`, {
            login: sessionStorage.getItem('login'),
            session: sessionStorage.getItem('session'),
            sub_code: subCode
        }, {
            headers: {'x-api-key': process.env.REACT_APP_CANCEL_SUBCRIPTIONS_KEY}
        })
        .then((res) => {
            if (res.data.status == 'successful') {
                props.toggleSpinner(false)
                setRefreshPage(!refreshPage)  
                props.updateSnackbar(res.data.message, 'green')
            } else {
                props.updateSnackbar(res.data.message, 'black')
            }
        })
        .catch((err) => {
            props.toggleSpinner(false)    
            props.updateSnackbar(err?.response?.data?.message, 'red')
            console.log(err.response)
        })
    }

    useEffect(() => {
        const detailsToFetch = ["idClient"];
        props.toggleSpinner(true)
        getUserDetails(detailsToFetch)
          .then((details) => {
              axios.post(`${BASE_URL_VOIPSWITCH}${ENDPOINTS['getClientPlanList']}`, {
                "clientId": details.idClient,
                "clientType": 32
                }, {
                    headers: {'Authorization': process.env.REACT_APP_TEMP_VS_KEY}
                })
                .then(res => {
                    axios.post(`${BACKEND}${BACKEND_ENDPOINTS['getsubscriptions']}`, {
                        login: sessionStorage.getItem('login'),
                        session: sessionStorage.getItem('session'),
                    }, {
                        headers: {'x-api-key': process.env.REACT_APP_GET_SUBCRIPTIONS_KEY}
                    })
                    .then((res) => {
                        const responsePlans = res.data.subscriptions
                        let newRows = []
                        for (let val of responsePlans)  {
                            newRows.push(createData(formatDateTime(val['created_at']), formatDateTime(val['next_payment_date']), val['plan_name'], val['amount'], val['interval'], val['sub_code'], val['status']))
                        }   
                        setRows(newRows)
                    })
                    
                    props.toggleSpinner(false)
                })
                .catch(error => {
                    props.updateSnackbar(error.message, 'red')
                    props.toggleSpinner(false)
                })
            })
            .catch((error) => {
                props.updateSnackbar(error.message, 'red')
                props.toggleSpinner(false)
            });
    }, [refreshPage])
      
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    
    return (
        <Paper component="div"
        sx={{ display: 'flex', flexDirection: 'column',
        pt: 3, pb: 10, mt: 3, mx: 'auto', width: {xs: '90vw', sm: '80vw'},
        borderRadius:4 }}>
            <ScrollToTopOnMount />
            <Box display='flex' px={3} pb={2} alignItems='center'>
                <RouterLink to='/Dashboard' state={{page: 'myaccount' }} style={{ textDecoration: 'none', marginTop: '-24px' }}>
                    <KeyboardBackspace sx={{ position: 'absolute', color: 'black' }}/>
                </RouterLink>
                <Typography variant="h5" fontWeight={700} sx={{ mx: 'auto'}}>Manage Plans</Typography>
            </Box>
            <Box bgcolor="#E2E2E2" width='100%' height='2px'></Box>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                        {columns2.map((column2) => (
                            <TableCell
                            key={column2.id}
                            align={column2.align}
                            style={{ minWidth: column2.minWidth }}
                            >
                            {column2.label}
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                            return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                {columns2.map((column2) => {
                                const value = row[column2.id];
                                
                                if (value == row['date'] && row['subCode'] != "") {
                                    return (
                                        <TableCell key={column2.id} align={column2.align}>
                                        {row['status'] == 'active' && 
                                            <Box component='img' pr={1} src={ic_recur} />
                                        }
                                        {column2.format && typeof value === 'number'
                                            ? column2.format(value)
                                            : value}
                                        </TableCell>
                                    );
                                }
                                if (value == row['duration'] && row['subCode'] != "") {
                                    return (
                                        <TableCell key={column2.id} sx={{'display': 'flex', 'alignItems': 'center', gap: 5, justifyContent: 'space-between'}} align={column2.align}>
                                        {column2.format && typeof value === 'number'
                                            ? column2.format(value)
                                            : value}
                                            {(() => {
                                                if (row['status'] == 'active') {
                                                    return <Typography onClick={() => cancelSubscription(row['subCode'])} className="pointer" variant="body2" color={'red'}>Cancel Plan</Typography>        
                                                } else {
                                                    return <Typography className="pointer" variant="subtitle2" color='grey'>{row['status']}</Typography>        
                                                }
                                            })()}
                                            
                                        </TableCell>
                                    );
                                }
                                if (value !== row['subCode'] && value !== row['status'])
                                    return (
                                        <TableCell key={column2.id} align={column2.align}>
                                        {column2.format && typeof value === 'number'
                                            ? column2.format(value)
                                            : value}
                                        </TableCell>
                                    );
                                })}
                                
                            </TableRow>
                            );
                        })}
                    </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[7, 50, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                </Paper> 
        </Paper>
    )
}