export function getTechPrefix(number) {
    let mapping = "";

    if (number.startsWith("02014")) {
        mapping = "0->3344 OR +->";
    } else if (number.startsWith("02018")) {
        mapping = "0->234 OR +->";
    } else if (number.startsWith("90")) {
        number = "0" + number;
        mapping = "234->0 OR +234->0";
    } else {
        mapping = "0->234 OR +->";
    }

    const techPrefix = "CP:!" + number + ";DP:" + mapping + ";TP:"

    return techPrefix
}