import React from 'react';
import { Navigate } from 'react-router-dom';

const isAuthenticated = () => {
  // Check if there is a valid authentication token in sessionStorage or localStorage
  const token = sessionStorage.getItem('token') || localStorage.getItem('token');
  // Return true if the user is authenticated, otherwise false
  
  return !!token;
};

const PrivateRoute2 = ({ children }) => {
  return isAuthenticated() ? (
    children
  ) : (
    <Navigate to="/Signin" replace state={{ from: '/AddFunds/:username' }} />
  );
};

export default PrivateRoute2;
