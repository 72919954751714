import { Paper, Typography, Box } from "@mui/material";
import successIcon from '../images/successIcon.svg';
import icon2 from '../images/padlock.svg';

import Button from "@mui/material/Button";

import React, { useContext } from "react";


import { Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from "axios";
import { BACKEND, ENDPOINTS } from "..";
import { NewUserContext } from "../pages/auth";


export default function TellStatus(props) {
    const navigate = useNavigate()  
    const { userDetails, setUserDetails } = useContext(NewUserContext);  

    if (props.status === "accountCreated")
        return (
            <Paper component="div"
                sx={{ display: 'flex', flexDirection: 'column',
                pb: 15, pt: 8, px: 4, mt: 3, width: {xs: '70vw', sm: '500px'},
                borderRadius:4 }}>
                <Box component='img'
                    width={120}
                    mx='auto'
                    src={successIcon} />
                <Typography variant="h3" fontWeight={700} textAlign='center'>Congratulations!!!</Typography>
                <Typography variant="h6" mt={2} fontWeight={700} textAlign='center'>Nativetalk Account has been created successfully.</Typography>
                <Typography variant="h6" fontWeight={700} textAlign='center'>You have earned FREE airtime for outgoing calls.</Typography>
                <Typography variant="h7" fontWeight={700} textAlign='center'>Your username is {userDetails?.number}</Typography>
                
                <Button  color='success' variant="contained" 
                onClick={() => {
                    return navigate('/Dashboard', { state: { page: 'overview' } });
                }}
                sx={{  mt: 5, py: 1.5, backgroundColor: '#3EBF0F', textTransform: 'none', width: '100%' }}>
                    Okay, Thank You!
                </Button>
                
            </Paper>
        )
    else if (props.status === "passwordChanged")
        return (
            <Paper component="div"
                sx={{ display: 'flex', flexDirection: 'column',
                pb: 15, pt: 8, px: 4, mt: 3, width: {xs: '70vw', sm: '400px'},
                borderRadius:4 }}>
                <Box component='img'
                    width={120}
                    mx='auto'
                    src={icon2} />
                <Typography variant="h5" fontWeight={700} textAlign='center'>
                    Your password has been changed successfully
                </Typography>
            
                <RouterLink to='/Dashboard' state={{page: 'overview' }} style={{ textDecoration: 'none', width: '10px !important' }}>
                    <Button  color='success' variant="contained"
                    sx={{  mt: 5, py: 1.5, backgroundColor: '#3EBF0F', textTransform: 'none', width: '100%' }}>
                        Okay, Thank You!
                    </Button>
                </RouterLink>
            </Paper>
        )
}