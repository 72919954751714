import { useEffect, useState } from "react"
import axios from 'axios'
import { BASE_URL_VOIPSWITCH, ENDPOINTS, BACKEND, BACKEND_ENDPOINTS, BASE_URL_VOIPSWITCH2, EMAILAPP } from "../../../index"
import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { getUserDetails } from "../../../utils/getUserDetails"
import { getTechPrefix } from "../../../utils/getTechPrefix"

export default function AddPlanWithPstk(props) {
    const navigate = useNavigate();
    const [currency, setCurrency] = useState()
    const [login, setLogin] = useState()
    const [idClient, setIdClient] = useState()
    const [publicKey, setPublicKey] = useState()
    const [number, setNumber] = useState()
    const [paymentUrl, setPaymentUrl] = useState()

    const [email, setEmail] = useState()

    useEffect(() => {
        const detailsToFetch = ["currency", "idClient", "payment_gateway", "email", "login", "webPassword", "reseller_id"];
        getUserDetails(detailsToFetch)
        .then((details) => {
            setCurrency(details.currency)
            setLogin(details.login)
            setIdClient(details.idClient)
            setEmail(details.email)
        })

        axios.post(`${BACKEND}${BACKEND_ENDPOINTS['addfunds']}`, {
            login: sessionStorage.getItem('login'),
            session: sessionStorage.getItem('session'),
        }, { headers: { 
            "x-api-key": process.env.REACT_APP_ADDFUNDS_KEY}
        })
        .then((res) => {
            if (res.data.status != "successful") {
                throw new Error(res.data.message);
            }

            setPublicKey(res.data.public_key)
        }) 
        .catch((err) => {
            props.updateSnackbar(err.message, 'red');
        })

        if (props.recurring) {
            initializePayment()
        }
    }, [])

    useEffect(() => {
        if (number !== undefined) {
            props.toggleSpinner(false);
            onPlanAdded();
        }
    }, [number]);

    function addPlan() {
        props.toggleSpinner(true)
        axios.post(`${BASE_URL_VOIPSWITCH}${ENDPOINTS['addPlan']}`, {
        clientId: idClient,
        clientType: 32,
        planId: props.plan,
        endDate: {"override":false,"date":"0001-01-01T00:00:00.0000000"}
        })
        .then((res) => {
            if (res.data.status === -1) {
                let message = res['data']['responseStatus']['message']
                throw new Error(message);
            } else {
                axios.post(`${EMAILAPP}${ENDPOINTS['accordMail']}`, {
                    "to_email": email,
                    "email_template": "template1",
                    "to_name": idClient,
                    "country_name": props.currency,
                    "plan_title": "Nativetalk " + props.currency + " plan " + props.description,
                    "amount": props.formattedPrice,
                    "minutes": props.description
                  }, {headers: {'Authorization': process.env.REACT_APP_EMAILAPP_KEY}}).catch(err => {})
                  props.updateSnackbar("Plan added successfully", 'green')
      
                  axios.post(`${BACKEND}${BACKEND_ENDPOINTS['getnumber']}`, {
                        login: sessionStorage.getItem('login'),
                        session: sessionStorage.getItem('session'),
                    }, {
                        headers: { "x-api-key": process.env.REACT_APP_GET_NUMBER_KEY }
                    })
                    .then((res) => {
                          if (res.data.number) {
                              setNumber(res.data.number)
                          } else {
                              addNumber()
                          }
                      })
                      .catch((err) => {
                        props.updateSnackbar(err.message, 'red')
                        props.toggleSpinner(false)
                      })
            }
        })
        .catch((err) => {
            props.toggleSpinner(false)
            props.updateSnackbar(err.message, 'red')
        })
    }
    
    const addNumber = () => {
        try {  
          // outgoing number
          axios.post(`${BASE_URL_VOIPSWITCH}${ENDPOINTS['getNumberList']}`, {
              "areaId":0,
              "countryId":1,
              "number":"*",
              "pageOffset":0,
              "pageSize":0
            })
            .then((res) => {
              let  i = -1;
              let selectedNumber = ''

              // loop through until an available number is found
              do {
                  i++    
                  selectedNumber = res['data']['data'][i]['number']
              } while(res['data']['data'][i]['status'] !== "Available" 
                      && i < (res['data']['data']).length - 1);
              
              if (res['data']['data'].length - 1 === i && res['data']['data'][i]['status'] !== "Available" ) {
                  props.updateSnackbar('no numbers available')
                  onPlanAdded()
              } else {
                    axios.post(`${BACKEND}${BACKEND_ENDPOINTS['addnumber']}`, {
                        login: sessionStorage.getItem('login'),
                        number: selectedNumber,
                        session: sessionStorage.getItem('session'),
                    }, { headers: { 
                        "x-api-key": process.env.REACT_APP_ADD_NUMBER_KEY}
                    })
                    .then((res) => {
                        if (res.data.status != "successful") {
                            throw new Error(res.data.message);
                        }
            
                        props.updateSnackbar('incoming number attached.', 'green')
                    }) 
                    .catch((err) => {
                        props.updateSnackbar(err.message, 'red');
                        onPlanAdded()
                    })
                }
            })    
            .catch((err) => {
              props.updateSnackbar('could not map number for incoming', 'grey')
            })
          }
          catch {
          }
      props.toggleSpinner(false)
    }

    function initializePayment() {
        let usePlanCode = props.planCode
        
        axios.post(`${BACKEND}${BACKEND_ENDPOINTS['initializepayment']}`, {
            login: sessionStorage.getItem('login'),
            email: email || `${login}@nativetalk.io`,
            amount: props.price * 100,
            plan_code: usePlanCode,
            callback_url: window.location.href,
            session: sessionStorage.getItem('session'),
        }, { headers: { 
            "x-api-key": process.env.REACT_APP_INITIATE_PAYMENT_KEY}
        })
        .then((res) => {
            if (res.data.status != "successful") {
                throw new Error(res.data.message);
            }
            setPaymentUrl(res.data.authorization_url)
        }) 
        .catch((err) => {
            props.updateSnackbar(err.message, 'red');
        })    
    }

    function makePayment() {
        if (props.recurring) {
            if (paymentUrl) window.location.href = paymentUrl
        }
        else {
            const PaystackPop = window.customLib['PaystackPop']
            let total_price = props.price * 100
            let handler = PaystackPop.setup({
                key: publicKey,
                email: `${login}@gmail.com`,
                currency: currency,
                amount: total_price,
                onClose: function(){
                    alert('Payment cancelled')
                },
                onerror: function(err) {
                    navigate("/Dashboard/Status", {state: {title: "Buy Plan", boolStatus: false, status: "Failed", error: err.message}})
                    props.updateSnackbar(err.message, 'red')
                },
                callback: function(response) {
                    axios.post(`${BASE_URL_VOIPSWITCH}${ENDPOINTS['addFunds']}`, {
                        "money": props.price,
                        "paymentType":"PrePaid", 
                        "idClient":idClient,
                        "clientType":32,
                        "addToInvoice":false,
                        "description":"Web top up"
                    })
                    .then(() => {
                        addPlan()                    
                    })
                    .catch((err) => {
                        props.updateSnackbar(err.message, 'red')
                        document.getElementById("circularProgress").style.visibility = "hidden"
                    })
                }
            })
            handler.openIframe();
        }
    }

    const onPlanAdded = () => {
        return navigate("/Dashboard", {state: { page: "buyPlanSuccessful", formattedPrice: props.formattedPrice, number: number}})
      }

    return (
        <>
            <Button onClick={makePayment} color='success' variant="contained"
            sx={{  mt: 7.5, py: 1.5, backgroundColor: '#3EBF0F', textTransform: 'none', width: '100%' }}>
                Make Payment
            </Button>                  
        </>
    )
}