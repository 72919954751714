import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import { Box, Button, Stack, FormControl, TextField, Typography, CircularProgress, Paper } from '@mui/material';
import cardNumberIcon from '../../../images/cardNumberIcon.svg';
import stripePaymentIcon from '../../../images/stripe-payment-icon.png';

import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useState, useEffect } from "react";
import axios from "axios";
import { BACKEND, ENDPOINTS, BASE_URL_VOIPSWITCH, BACKEND_ENDPOINTS } from "../../../index";

import { Navigate, useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace';
import { getUserDetails } from "../../../utils/getUserDetails";

export function CheckoutForm(props) {
  const navigate = useNavigate()
  const placeholder = "Amount: $" + props.amount
  const [clientSecret, setClientSecret] = useState('')
  const [paymentSuccess, setPaymentSuccess] = useState(false)
  const [paymentFailed, setPaymentFailed] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [disableSubmit, setDisableSubmit] = useState(false)
  const [idClient, setIdClient] = useState('')

  useEffect(() => {
      if (!sessionStorage.getItem('session')) {
          sessionStorage.clear()
          return navigate('/Signin');
      }  
      
      axios.post(`${BACKEND}${BACKEND_ENDPOINTS['getStripeSecret']}`, {
          login: sessionStorage.getItem('login'),
          amount: Number(props.amount) * 100,
          session: sessionStorage.getItem('session')
      }, { headers: { 
        "x-api-key": process.env.REACT_APP_STRIPE_CREATE_PAYMENT_KEY}
      })
      .then((res) => {
        setClientSecret(res.data.clientSecret)
      })
      .catch((err) => {
      })

      const detailsToFetch = ["login", "idClient"];
        getUserDetails(detailsToFetch)
      .then((details) => {
          setIdClient(details.idClient)
      })
      .catch((error) => {
      });
    }, [props.amount])

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    setDisableSubmit(true)
    document.getElementById("circularProgress").style.visibility = "visible"

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    
    // CardCvcElement, CardExpiryElement, CardNumberElement
    const result1 = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
    })
    if (result1.error) {
      // Show error to your customer (for example, payment details incomplete)      
      document.getElementById("circularProgress").style.visibility = "hidden"
      setErrorMessage(result1.error['message'])
      
      setDisableSubmit(false)
      setPaymentFailed(true)
    } else {
      const result2 = await stripe.confirmCardPayment(clientSecret, {
          payment_method: result1.paymentMethod.id,
          // receipt_email: "jtobi8161@gmail.com"
        })  

      if (result2.error) {
      // Show error to your customer (for example, payment details incomplete)

      document.getElementById("circularProgress").style.visibility = "hidden"
      setErrorMessage(result2.error['message'])

      setDisableSubmit(false)
      setPaymentFailed(true)
      } else {
        document.getElementById("circularProgress").style.visibility = "hidden"
        setDisableSubmit(false)
        
        axios.post(`${BASE_URL_VOIPSWITCH}${ENDPOINTS['addFunds']}`, {
          "money":props.amount,
          "paymentType":"PrePaid", 
          "idClient":idClient,
          "clientType":32,
          "addToInvoice":false,
          "description":"Web top up"
        })
        .then((res) => {
          setPaymentSuccess(true)
        })
        .catch((err) => {
          document.getElementById("circularProgress").style.visibility = "hidden"
        })
      } 
    }
    
  };

  if (paymentSuccess)
    return <Navigate to="/Dashboard" state={{ page: "addFundsSuccessful"}} />
  if (paymentFailed)
    return <Navigate to="/Dashboard" state={{ page: "addFundsFailed", error: errorMessage}} />
  else
  return (
    <Box display='flex' justifyContent='center'>
      <Paper sx={{pt: 3, pb: 10, mt: 3, px: {xs: 2, md: 5}, width: {xs: '80vw', md: '50vw', lg: '500px'}}} position="relative">
      
        <CircularProgress id="circularProgress" sx={{ visibility:"hidden", position: "absolute", left: "45%", top: "45%" }} color="success" />
        <Box display='flex' px={3} alignItems='center'>
            <RouterLink to='/AddFunds' style={{ textDecoration: 'none', marginTop: '-24px' }}>
                <KeyboardBackspace sx={{ position: 'absolute', color: 'black' }}/>
            </RouterLink>
            <Typography variant="h5" fontWeight={700} sx={{ mx: 'auto'}}>Add Funds</Typography>
        </Box>
        <Stack component='form' onSubmit={(e) => handleSubmit(e)} direction='column' pt={3}> 
                <FormControl sx={{ pt: 3 }} >
                  <TextField disabled placeholder={placeholder}/>
                </FormControl>
                
                <Typography variant="p" mt={5} mb={2}>Card Number</Typography>
                <Box position='relative' border={1} borderRadius={1} borderColor='#CBCBCB' px={3} py={2}>
                  <CardNumberElement />
                  <Box component='img'
                       position='absolute'
                       right="15px"
                       top="15px"
                       src={cardNumberIcon} />
                  
                </Box>
                <Stack display='flex' direction='row' mt={4} spacing={3}>
                  <Box flexGrow={1}>
                    <Typography variant='p'>Expiration Date</Typography>                    
                  
                    <Box border={1} mt={2} borderRadius={1} borderColor='#CBCBCB' px={3} py={2}>
                      <CardExpiryElement />
                    </Box>
                  </Box>  
                  <Box flexGrow={1}>
                    <Typography  variant='p'>CVV</Typography>
                    <Box border={1} mt={2} borderRadius={1} borderColor='#CBCBCB' px={3} py={2}>
                      <CardCvcElement />
                    </Box>
                  </Box>
                </Stack>
                  <Button disabled={disableSubmit} type='submit' color='success' variant="contained"
                    sx={{  mt: 7.5, py: 1.5, backgroundColor: '#3EBF0F', textTransform: 'none', width: '100%' }}>
                        Make Payment
                    </Button>                  
            <Box component='img' 
                  mt={10}
                  sx={{width: {xs: '50vw', md: '40vw', lg: '250px'}}} 
                  src={stripePaymentIcon} 
                  mx='auto'
                 />
        </Stack>
      </Paper>
    </Box>
  )
}