import Box from "@mui/material/Box";

import Header from "../../components/header"
import { Navigate, useLocation } from "react-router-dom";

import Overview from "../../components/dashboard/overview";
import AddFunds, { AddFundsSuccessful, AddFundsFailed } from "../../components/dashboard/checkouts/addFunds";
import ConfirmAddFunds from "../../components/dashboard/confirmAddFunds";
import MyAccount from "../../components/dashboard/myAccount";
import EditProfile from "../../components/dashboard/editProfile";
import ChangePassword from "../../components/dashboard/changePassword";
import ContactSupport from "../../components/dashboard/contactSupport";
import CallRecords from "../../components/dashboard/callRecords";
import { BuyPlanFailed, BuyPlanSuccessful, ConfirmBuyPlan } from "../../components/dashboard/plansConfirmation";
import { CheckoutForm } from "../../components/dashboard/checkouts/checkoutForm";
import { CheckoutForm2 } from "../../components/dashboard/AddFunds/checkoutForm2";
import { MakePlanPayment } from "../../components/dashboard/checkouts/makePlanPayment";
import RecurringPlans from "../../components/dashboard/recurringPlans";
import OneTimePlans from "../../components/dashboard/oneTimePlans";

export default function Dashboard({ updateSnackbar, toggleSpinner }) {
    const location = useLocation();
    const { page, ...rest } = location.state || {};

    // Now `rest` contains all properties from `location.state` except 'page'
    const props = rest;


    
    if (!sessionStorage.getItem('session') || sessionStorage.getItem('session') === "undefined")
        return <Navigate to="/Signin" />
    else
    return (
        <Box component='div' py={15} bgcolor="#F8F8F8" sx={{ px: {xs: 3, sm: 5,  lg: 15}, minHeight:'100vh'}}>
            <Header />
            {(() => {
                if (page == 'overview')
                    return  <Overview updateSnackbar={updateSnackbar} toggleSpinner={toggleSpinner} />

                // Normal Add funds with stripe
                else if (page == 'makePayment')
                    return <CheckoutForm amount={props.amount} />

                // Make plan payment options
                else if (page == 'makePlanPayment')
                    return <MakePlanPayment 
                    updateSnackbar={updateSnackbar} 
                    toggleSpinner={toggleSpinner} 
                    {...props}/>

                // Recurring plan payment
                else if (page == 'makePayment2')
                    return <CheckoutForm2 product={props.product}/>

                else if (page == 'confirmAddFunds')
                    return <ConfirmAddFunds />
                else if (page == 'addFundsSuccessful')
                    return <AddFundsSuccessful />
                else if (page == 'addFundsFailed')
                    return <AddFundsFailed error={props.error} />
                else if (page == 'myaccount')
                    return <MyAccount updateSnackbar={updateSnackbar} toggleSpinner={toggleSpinner} />
                else if (page == 'editprofile')
                    return <EditProfile />
                else if (page == 'changepassword')
                    return <ChangePassword />
                else if (page == 'contactsupport')
                    return <ContactSupport />
                else if (page == 'callrecords')
                    return <CallRecords />
                else if (page == 'recurringplans')
                    return <RecurringPlans updateSnackbar={updateSnackbar} toggleSpinner={toggleSpinner} />
                else if (page == 'onetimeplans')
                    return <OneTimePlans />


                else if (page == 'confirmBuyPlan') 
                    return <ConfirmBuyPlan {...props} />
                else if (page == 'buyPlanSuccessful') 
                    return <BuyPlanSuccessful {...props} />
                else if (page == 'buyPlanFailed')
                    return <BuyPlanFailed message={props.message} />
                
                else
                    return  <Overview updateSnackbar={updateSnackbar} toggleSpinner={toggleSpinner} />

            })()}
        </Box>
    )
}