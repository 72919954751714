import { Box, Paper, Typography, Stack, Button, Container } from "@mui/material";
import { List, ListItem, ListItemText, ListItemIcon, IconButton, Link, Snackbar } from "@mui/material";

import { Link as RouterLink, Navigate, useNavigate } from "react-router-dom";

import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import recurringPlansIcon from '../../images/callRecordsIcon.svg';
import editProfileIcon from '../../images/editProfileIcon.svg';
import changePasswordIcon from '../../images/changePasswordIcon.svg';

import { useState, useEffect, useContext } from "react";
import { ENDPOINTS, BASE_URL_VOIPSWITCH, BASE_URL_VOIPSWITCH2, BACKEND, BACKEND_ENDPOINTS } from "../../index";
import axios from "axios";
import ScrollToTopOnMount from "../scrolltoview";
import { getUserDetails } from "../../utils/getUserDetails";

export default function MyAccount(props) {    
    const navigate = useNavigate()

    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [snackColor, setSnackColor] = useState('black')

    const [idClient, setIdClient] = useState('')
    const [login, setLogin] = useState()
    const [currency, setCurrency] = useState('')
    const [acctBalance, setAcctBalance] = useState('')
    const [bundleBalance, setBundleBalance] = useState('')
    const [phoneNumber, setPhoneNumber] = useState("")

    const [techPrefix, getTechPrefix] = useState(false)

    const detailsToFetch = ["login", "creditBalance", "currency", "idClient", "webPassword"];
    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setOpen(false);
    };

    useEffect(() => {
        if (techPrefix) {
            axios.post(`${BASE_URL_VOIPSWITCH}${ENDPOINTS['getTechPrefix']}`, {
                "clientId": idClient,
                "techPrefix": ""
            }, { headers: { 
                "Authorization": process.env.REACT_APP_TEMP_VS_KEY}
            })
            .then((res) => {
                let first = res.data.techPrefix.split('!')[1];
                let second = first?.split(';')[0];
                if (phoneNumber === "") setPhoneNumber(second);
            })
            .catch((err) => {
                props.updateSnackbar(err.message, 'red');
            });
        }
    }, [techPrefix])
    
    useEffect(() => {
        // Get client Did
        getUserDetails(detailsToFetch)
            .then((details) => {
                setLogin(details.login);
                setCurrency(details.currency);
                setAcctBalance(details.creditBalance);
                setBundleBalance(details.bundleBalance);
                setIdClient(details.idClient);
    
                return axios.post(`${BACKEND}${BACKEND_ENDPOINTS['getnumber']}`, {
                    login: sessionStorage.getItem('login'),
                    session: sessionStorage.getItem('session'),
                }, {
                    headers: { "x-api-key": process.env.REACT_APP_GET_NUMBER_KEY }
                });
            })
            .then((res) => {
                if (res.data.status === "successful") { // Adjusted logic to check for "successful" status
                    setPhoneNumber(res.data.number);
                } else {
                    props.updateSnackbar(res.data.message || 'Error fetching number', 'black');
                    getTechPrefix(true)
                }
            })
            .catch((err) => {
                if (err.errorCode == 1) {
                    navigate('/Signin')
                    console.log(err)
                    sessionStorage.clear()
                }
                props.updateSnackbar(err.message, 'red');             
            });
        
    }, [login]); // Depending on other variables might also need to include them in the dependencies array
    

        
    const logout = () => {  
        sessionStorage.clear()
        return navigate('/Signin');
    }

    return (
        <Paper component="div"
            sx={{ display: 'flex', flexDirection: 'column',
            pt: 3, pb: 10, mt: 3, mx: 'auto', width: {xs: '90vw', sm: '600px'},
            borderRadius:4 }}>
        <ScrollToTopOnMount />
        <Box display='flex' px={3} pb={2} alignItems='center'>
            <RouterLink to='/Dashboard' state={{page: 'overview' }} style={{ textDecoration: 'none', marginTop: '-24px' }}>
                <KeyboardBackspace sx={{ position: 'absolute', color: 'black' }}/>
            </RouterLink>
            <Typography variant="h5" fontWeight={700} sx={{ mx: 'auto'}}>My Account</Typography>
        </Box>
        <Box bgcolor="#E2E2E2" width='100%' height='2px'></Box>

        <Box px={3}>
            <Stack direction='column' pt={2} textAlign="center">
                <Box fontSize="80px" >
                    <AccountCircle fontSize="inherit" />
                </Box>
                <Typography variant="h6" mt={-2} >{login}</Typography>
                <Typography variant="body2" color='#929292'>{phoneNumber}</Typography>

                <Container  sx={{ display: 'flex', bgcolor: '#E7F7E1', borderRadius: 3, py: 1, mt: 3 }}>
                    <Box>
                        <Typography variant="body2" >Wallet Balance</Typography>
                        <Typography variant="h5" fontWeight={700}>{currency} {acctBalance}</Typography>
                    </Box>
                    <RouterLink to='/AddFunds' 
                                style={{ textDecoration: 'none', 
                                          padding: '0px', 
                                          marginTop: '-40px',
                                          marginLeft: 'auto'  }}>
                        <Button  color='success' variant="contained"
                        sx={{  mt: 5, py: 1.5, backgroundColor: '#3EBF0F', textTransform: 'none' }}>
                            Add Funds
                        </Button>
                    </RouterLink>
                </Container>         
                <Container  sx={{ display: 'flex', bgcolor: '#E7F7E1', borderRadius: 3, py: 1, mt: 3 }}>
                    <Box>
                        <Typography variant="body2" >Bundle Balance</Typography>
                        <Typography variant="h5" fontWeight={700}>{currency} {bundleBalance}</Typography>
                    </Box>
                
                </Container>            
                <List sx={{ width: '100%', bgcolor: 'transparent' }}>
                    <RouterLink to='/Dashboard' state={{ page: 'recurringplans'}} style={{textDecoration: "none"}}>
                        <ListItem
                        key={0}
                        secondaryAction={
                            <IconButton aria-label="call records">
                                <RouterLink to='/Dashboard' state={{ page: 'recurringplans'}}>
                                    <ArrowForwardIosIcon  sx={{color:"black"}} />
                                </RouterLink>
                            </IconButton>
                        }
                        sx={{ my: 2, bgcolor: 'background.paper', borderRadius: 3, color: "black"}}
                        >
                            <ListItemIcon>
                                <Box component='img'
                                    src={recurringPlansIcon} />
                            </ListItemIcon>    
                            <ListItemText sx={{ ml: -1 }} primary="Plans"  />
                        </ListItem>
                    </RouterLink>
                    
                    <RouterLink to='/Dashboard' state={{ page: 'editprofile'}} style={{ textDecoration: 'none'}} >
                    <ListItem
                    key={1}
                    sx={{ my: 2, bgcolor: 'background.paper', borderRadius: 3, color: "black"}}
                    secondaryAction={
                        <IconButton aria-label="edit profile" >
                            <RouterLink to='/Dashboard' state={{ page: 'editprofile'}}>
                                <ArrowForwardIosIcon  sx={{color:"black"}} />
                            </RouterLink>    
                        </IconButton>
                    }
                    >
                        
                        <ListItemIcon>
                            <Box component='img'
                                    src={editProfileIcon} />
                        </ListItemIcon>  
                        <ListItemText sx={{ ml: -1 }} primary="Edit Profile" />
                    </ListItem>
                    </RouterLink>
                    <RouterLink to='/Dashboard' state={{ page: 'changepassword'}} style={{textDecoration: 'none'}}>
                        <ListItem
                        key={2}
                        sx={{ my: 2, bgcolor: 'background.paper', borderRadius: 3, color: "black"}}
                        secondaryAction={
                            <IconButton aria-label="change password">
                                <RouterLink to='/Dashboard' state={{ page: 'changepassword'}}>
                                    <ArrowForwardIosIcon  sx={{color:"black"}} />
                                </RouterLink>    
                            </IconButton>
                        }
                        >
                            <ListItemIcon>
                                <Box component='img'
                                    src={changePasswordIcon} />
                            </ListItemIcon>        
                            <ListItemText sx={{ ml: -1 }} primary="Change Password" />
                        </ListItem>
                    </RouterLink>
                </List>    

                <Box bgcolor="#F7F7F7" width='100%' height='10px'></Box>

                <Link
                onClick={(e) => {
                    setOpen(true)
                    setSnackColor('red')
                    setSnackMessage("Logged out")
                    setTimeout(logout, 1000)
                }}
                style={{ cursor: 'pointer', textDecoration: 'none', color: '#FF1515', marginTop: '20px' }}>
                        Log out
                </Link>
            </Stack>
        </Box>
        <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                message={snackMessage}
                // action={action}

                sx={{
                    "& .MuiSnackbarContent-root": {
                      backgroundColor: `${snackColor}`,
                    },
                  }}
                />
    </Paper>
    )
}